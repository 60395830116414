<script lang="tsx">
import type { HTMLAttributes, SlotsType } from 'vue'
import type { PaddingBehavior, SizeProp } from '@core-types/components'
import { defineRuntimeProps, type RuntimeProps } from '@core/app/utils/components'

// private props
type _BaseUiSelectInputProps = {
    placeholderAriaHidden?: boolean
    disabled: boolean
    invalid: boolean
    loading: boolean
    leadingRendered: boolean
    trailingRendered: boolean
    role?: 'combobox' | undefined
    /**
     * Whether the div itself can be focused or not.
     * @default false
     */
    focusable?: boolean
}

// public props
export type BaseUiSelectInputProps<Colors extends string, Variants extends string, Sizes extends string> = {
    /**
     * The placeholder text for the input.
     */
    placeholder?: string
    color?: Colors
    variant?: Variants
    size?: Sizes

    /**
     * Whether to adjust the padding of the input based on the presence of the leading and trailing slots.
     * If set to `static`, the padding will be the same regardless of the presence of the slots.
     * If set to `dynamic`, the padding will be adjusted based on the presence of the slots to use the vertical padding
     * on the horizontal sides as well for the sides with a slot.
     * @default 'dynamic'
     */
    paddingBehavior?: PaddingBehavior
    /**
     * Whether to use equal padding on all sides of the input.
     */
    square?: boolean
    inline?: boolean
    /**
     * Class to be applied to the leading slot wrapper
     */
    leadingClass?: HTMLAttributes['class']

    /**
     * Class to be applied to the trailing slot wrapper
     */
    trailingClass?: HTMLAttributes['class']
}

// public props
export const getBaseUiSelectInputRuntimeProps = <Colors extends string, Variants extends string, Sizes extends string, TProps extends BaseUiSelectInputProps<Colors, Variants, Sizes>>(options?: ComponentOverrideOptions<any, TProps>):
RuntimeProps<Pick<TProps, keyof BaseUiSelectInputProps<Colors, Variants, Sizes>>> =>
    defineRuntimeProps<BaseUiSelectInputProps<Colors, Variants, Sizes>>({
        placeholder: { type: String },
        // @ts-ignore
        color: { type: String },
        // @ts-ignore
        variant: { type: String },
        // @ts-ignore
        size: { type: String },
        paddingBehavior: { type: String as PropType<PaddingBehavior>, default: 'dynamic' },
        square: { type: Boolean },
        inline: { type: Boolean },
        leadingClass: { type: String },
        trailingClass: { type: String },
    }, options)

type FullBaseUiSelectInputProps<Colors extends string, Variants extends string, Sizes extends string> = BaseUiSelectInputProps<Colors, Variants, Sizes> & _BaseUiSelectInputProps

type BaseUiSelectInputSlots = {
    leading: {}
    trailing: {}
    text: {}
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseUiSelectInput<
    Colors extends string,
    Variants extends string = '',
    Sizes extends string = SizeProp,
>(options?: ComponentOverrideOptions<ComponentOptions, FullBaseUiSelectInputProps<Colors, Variants, Sizes>, BaseUiSelectInputSlots>) {
    return defineComponent(
        (props: FullBaseUiSelectInputProps<Colors, Variants, Sizes>, ctx) => {

            return () => (
                <div
                    class={['sim-select', {
                        'sim-select--disabled': props.disabled,
                        'sim-select--loading': props.loading,
                        'sim-select--error': props.invalid,
                        'sim-select--inline': props.inline,
                        [`c-${props.color}`]: props.color,
                        [`v-${props.variant}`]: props.variant,
                        [`s-${props.size}`]: props.size,
                        'sim-select--leading': props.leadingRendered && props.paddingBehavior === 'dynamic',
                        'sim-select--trailing': props.trailingRendered && props.paddingBehavior === 'dynamic',
                        'sim-select--square': props.square,
                    }]}
                    role={props.role}
                    tabindex={props.focusable ? '0' : undefined}
                >
                    {props.leadingRendered && (
                        <div class={['sim-select__sides', props.leadingClass]} aria-hidden="true">
                            {renderSlot(ctx.slots.leading, options?.slots?.leading, {})}
                        </div>
                    )}

                    <div class="sim-select__text" {...(props.placeholderAriaHidden ? { 'aria-hidden': true } : {})}>
                        {renderSlot(ctx.slots.text, options?.slots?.text, {}, props.placeholder ? (
                            <span class="sim-select__placeholder">
                                {props.placeholder}
                            </span>
                        ) : undefined)}
                    </div>

                    {renderSlot(ctx.slots.default, options?.slots?.default, {})}

                    {props.trailingRendered && (
                        <div class={['sim-select__sides', props.trailingClass]} aria-hidden="true">
                            {renderSlot(ctx.slots.trailing, options?.slots?.trailing, {})}
                        </div>
                    )}
                </div>
            )
        },
        {
            props: {
                ...getBaseUiSelectInputRuntimeProps<Colors, Variants, Sizes, FullBaseUiSelectInputProps<Colors, Variants, Sizes>>(options),
                ...defineRuntimeProps<_BaseUiSelectInputProps>({
                    placeholderAriaHidden: { type: Boolean },
                    disabled: { type: Boolean, required: true },
                    invalid: { type: Boolean, required: true },
                    loading: { type: Boolean, required: true },
                    leadingRendered: { type: Boolean, required: true },
                    trailingRendered: { type: Boolean, required: true },
                    // @ts-ignore
                    role: { type: String },
                    focusable: { type: Boolean },
                }),
            },
            slots: Object as SlotsType<BaseUiSelectInputSlots>,
            emits: {},
        }
    )
}

export default defineComponentBaseUiSelectInput()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiSelectInput.scss" as *;

</style>
