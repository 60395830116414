<script lang="tsx">
import { defineComponentBaseUiSelect } from '@core/app/components/base/ui/select/BaseUiSelect.vue'
import { IconChevron } from '#components'
import type { SelectColors, SelectSizes, SelectVariants } from './BaseUiSelectInput.vue'

export default defineComponentBaseUiSelect<SelectColors, SelectVariants, SelectSizes>({
    slots: {
        dropdownIcon: () => <IconChevron size="xs" />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiSelect" as *;

</style>
